
import Vue from "vue";
import LayoutPlain from "@/layouts/LayoutPlain.vue";

const Component = Vue.extend({
  data() {
    return {
      username: "",
      email: "",
      password: "",
      passwordConfirm: ""
    };
  },
  methods: {
    userSignUp(): void {
      if (this.comparePasswords !== true || this.minlengthPassword !== true) {
        return;
      }
      this.$store.dispatch("authentication/register", {
        username: this.username,
        email: this.email,
        password: this.password
      });
    }
  },
  computed: {
    comparePasswords(): true | string {
      return this.password === this.passwordConfirm
        ? true
        : "Passwords don't match";
    },
    minlengthPassword(): true | string {
      return this.password.length >= 8 ? true : "Password too short";
    }
  },
  created(): void {
    this.$emit("update:layout", LayoutPlain);
  }
});
export default Component;
